<template>
  <el-container id="allocation-content">
    <el-aside width="585px">
      <el-container id="trade">
        <el-header height="54px">
          <el-tabs v-model="statusTab" @tab-click="changeStatusTab">
            <el-tab-pane label="待打印" name="waitPrint"></el-tab-pane>
            <el-tab-pane label="待登记" name="waitCheckStock"></el-tab-pane>
            <el-tab-pane label="已完成" name="done"></el-tab-pane>
          </el-tabs>
        </el-header>
        <el-header height="40px">
          <div class="search">
            <el-date-picker v-model="queryData.dateRange" type="daterange" value-format="yyyy-MM-dd" range-separator="~"
              :editable="false" :clearable="false" @change="query"></el-date-picker>
          </div>
          <div class="search">
            <el-select v-model="queryParam.searchField" @change="(e) => {
              queryData.supplierQuery = e == 'supplier';
              queryParam.searchValue = '';
              queryParam.supplierIDs = [];
              queryData.supplierIDs = [];
              queryParam.WID = 0;
            }
              ">
              <el-option value="supplier" label="供应商"></el-option>
              <el-option value="purchaserName" label="客户"></el-option>
              <el-option value="itemNo" label="货号"></el-option>
              <el-option value="warehouse" label="仓库" v-if="$store.state.routes.systemSetting.MultiWarehouse"></el-option>
              <el-option value="store" label="档口" v-if="$store.state.routes.systemSetting.MultiStore"></el-option>
            </el-select>
            <el-select v-show="queryData.supplierQuery && queryParam.status != 5" v-model="queryParam.supplierIDs"
              placeholder="请选择" clearable multiple collapse-tags filterable>
              <el-option v-for="supplier in queryData.suppliers" :key="'supplier-' + supplier.SupplierID"
                :value="supplier.SupplierID" :label="supplier.SupplierName"></el-option>
            </el-select>
            <el-select v-show="queryData.supplierQuery && queryParam.status == 5" v-model="queryData.supplierIDs"
              placeholder="请选择" clearable multiple collapse-tags filterable>
              <el-option v-for="supplier in queryData.allSuppliers" :key="'all-supplier-' + supplier.SupplierID"
                :value="supplier.SupplierID" :label="supplier.SupplierName"></el-option>
            </el-select>
            <warehouse-select
              v-if="$store.state.routes.systemSetting.MultiWarehouse && queryParam.searchField == 'warehouse'"
              :value.sync="queryParam.WID" select_style="width:170px;"></warehouse-select>
            <store-select v-if="$store.state.routes.systemSetting.MultiStore && queryParam.searchField == 'store'"
              :value.sync="queryParam.StoreID" select_style="width:170px;"></store-select>
            <el-input
              v-show="!queryData.supplierQuery && queryParam.searchField != 'warehouse' && queryParam.searchField != 'store'"
              v-model="queryParam.searchValue" @keydown.enter.native="query"></el-input>
          </div>
          <div class="search">
            <el-badge :value="newTradeCount" :max="99" :hidden="newTradeCount <= 0">
              <el-button type="primary" icon="el-icon-refresh" @click="query">刷新</el-button>
            </el-badge>
          </div>
        </el-header>
        <el-main>
          <grid-table v-show="statusTab == 'waitPrint'" ref="waitPrint" :tableConfig="tableConfig.waitPrint"
            :showIndexColumn="false" :showLastEmptyColumn="false" :showPagination="false"
            @selectionChange="changeSelection" @handleSelect="selectSingle" @handleSelectAll="selectAll"
            @handleRowClick="clickRow">
            <template slot="PurchaserName" slot-scope="{ params }">
              <div :class="params.row[params.column.props[1]] == 1 ? 'proxy-status' : ''">
                {{ params.row[params.column.props[0]] }}
              </div>
            </template>
            <template slot="NumInfo" slot-scope="{ params }">
              {{ params.row[params.column.props[0]] - params.row[params.column.props[1]] }}
            </template>
          </grid-table>
          <grid-table v-show="statusTab == 'waitCheckStock'" ref="waitCheckStock"
            :tableConfig="tableConfig.waitCheckStock" :showIndexColumn="false" :showLastEmptyColumn="false"
            :showPagination="false" @selectionChange="changeSelection" @handleSelect="selectSingle"
            @handleSelectAll="selectAll" @handleRowClick="clickRow">
            <template slot="NumInfo" slot-scope="{ params }">
              {{ params.row[params.column.props[0]] - params.row[params.column.props[1]] }}
            </template>
          </grid-table>
          <grid-table v-show="statusTab == 'done'" ref="done" :tableConfig="tableConfig.done" :showIndexColumn="false"
            :showLastEmptyColumn="false" :showPagination="false" @selectionChange="changeSelection"
            @handleSelect="selectSingle" @handleSelectAll="selectAll" @handleRowClick="clickRow">
            <template slot="PurchaserName" slot-scope="{ params }">
              <div :class="params.row[params.column.props[1]] == 1 ? 'proxy-status' : ''">
                {{ params.row[params.column.props[0]] }}
              </div>
            </template>
            <template slot="NumInfo" slot-scope="{ params }">
              {{ params.row[params.column.props[0]] - params.row[params.column.props[1]] }}
            </template>
          </grid-table>
        </el-main>
        <el-footer height="40px">
          <span>客户数：{{ statistics.purchaserCount }}</span>
          <span>合计数量：{{ statistics.totalCount }}</span>
          <span>合计金额：{{ statistics.totoalAmount | decimalFormat }}</span>
        </el-footer>
      </el-container>
    </el-aside>
    <el-main>
      <el-container id="print-view">
        <el-header height="54px">
          <el-tabs v-model="printViewTab" @tab-click="changePrintViewTab">
            <el-tab-pane v-for="field in configField.fields" :key="field.prop" :label="field.label + '视图'"
              :name="field.prop"></el-tab-pane>
            <el-tab-pane label="单据列表" name="list" v-if="statusTab == 'waitCheckStock'"></el-tab-pane>
          </el-tabs>
          <el-switch style="position: absolute;top: 10px;right: 10px;" v-model="printConfig.checkPrintResult"
            active-text="校验打印结果" @change="$refs.printConfig.saveConfig('checkPrintResult');" />
        </el-header>
        <el-main>
          <div v-for="field in configField.fields" :key="field.prop" :class="`paper-wrapper paper-${field.prop}`"
            v-show="printViewTab == field.prop">
          </div>
          <grid-table ref="waitCheckStockTable" v-show="printViewTab == 'list'" :tableConfig="waitCheckStockTableConfig"
            :showCheckboxColumn="false" :showIndexColumn="false" :showLastEmptyColumn="false" :showPagination="false">
          </grid-table>
        </el-main>
        <el-footer height="40px">
          <span v-show="printViewTab == 'a4'">共 {{ a4TotalPage }} 页，前往<el-input v-model.number="jumpPage"
              @keydown.enter.native="jumpToPage"></el-input>页</span>
          <span v-show="printViewTab != 'list'">
            <el-button type="primary" v-show="printViewTab == 'bill'" @click="sendBill">发送小票</el-button>
            <el-button type="danger" v-show="statusTab == 'waitPrint'" @click="del">删除</el-button>
            <el-button type="danger" plain @click="recall">撤回</el-button>
            <el-button type="primary" plain v-show="statusTab == 'waitPrint'" @click="savePrint">跳过</el-button>
            <el-button type="primary" @click="print">打印</el-button>
            <el-button v-show="statusTab == 'waitCheckStock'" type="primary" @click="saveCheckStock">登记</el-button>
          </span>
        </el-footer>
      </el-container>
      <div v-for="field in configField.fields" v-show="field.prop == printViewTab" :key="'printType' + field.prop"
        class="print-view-left">
        <template v-if="printConfig[field.prop].printType != null">
          <div v-for="cItem in field.properties.filter(t => t.prop == 'printType')[0].options"
            :key="field.prop + cItem.value"
            :class="{ 'is-active': cItem.value == printConfig[field.prop].printType && field.prop == printViewTab }"
            @click="printConfig[field.prop].printType = cItem.value; $refs.printConfig.saveConfig('printType');">
            {{ cItem.label }}
          </div>
        </template>
      </div>
    </el-main>
    <el-aside width="285px">
      <stall-config ref="printConfig" :field.sync="configField" :value.sync="printConfig" @handleSave="savePrintConfig"
        :view-tab.sync="printViewTab" :level="0" source="print">
      </stall-config>
    </el-aside>
    <trade-print ref="tradePrint" :status-tab="statusTab" :print-view-tab.sync="printViewTab"
      :print-config.sync="printConfig" :a4-total-page.sync="a4TotalPage" @savePrintSuccess="query"></trade-print>
    <helper-center ref="helperCenter" position="allocation" :visible="true"></helper-center>
  </el-container>
</template>

<script>
import { submit } from '@/api/base';
import { getDate, getColumnValue } from '@/utils/tool';
import TradePrint from '@/views/stall/trade/components/TradePrint.vue';
import StallConfig from '@/views/stall/trade/components/StallConfig.vue';
import HelperCenter from '@/views/stall/trade/components/HelperCenter.vue';
import WarehouseSelect from "@/views/components/common/WarehouseSelect.vue";
import StoreSelect from "@/views/components/common/StoreSelect.vue";

export default {
  name: 'stallAllocation',
  mounted() {
    this.$nextTick(async () => {
      await this.init();
      if (this.printConfig.withSupplierGroup) {
        this.tableConfig.waitPrint.columns[2].isHidden = false;
      }
      this.$refs.tradePrint.init();
    });
  },
  beforeDestroy() {
    this.autoPolling = false;
  },
  components: {
    TradePrint,
    StallConfig,
    HelperCenter,
    WarehouseSelect,
    StoreSelect
  },
  data() {
    return {
      statusTab: 'waitPrint',
      printViewTab: 'bill',
      autoPolling: true,
      newTradeCount: 0,
      pxPerMm: null,
      queryParam: {
        status: 1,
        searchField: 'supplier',
        searchValue: '0',
        startTime: '',
        endTime: '',
        supplierIDs: [],
        WID: 0,
        StoreID: 0,
        withSupplierGroup: false,
      },
      queryData: {
        dateRange: [getDate(), getDate()],
        supplierQuery: true,
        suppliers: [],
        allSuppliers: [],
        supplierIDs: [],
      },
      tableConfig: {
        waitPrint: {
          url: '/api/stall/getList',
          rowKey: 'RowKey',
          columns: [
            {
              label: '接单时间',
              prop: 'CreateTime',
              type: 'text-datetime',
              dateFormat: '<strong>MM-dd</strong> HH:mm',
              width: '96px',
            },
            {
              label: '客户',
              prop: 'PurchaserName',
              props: ['PurchaserName', 'ProxyStatus'],
              type: 'custom-operation',
            },
            {
              label: '供应商',
              prop: 'SupplierName',
              type: 'text',
              isHidden: true,
            },
            {
              label: '仓库',
              prop: 'WName',
              props: ['WName'],
              type: 'text',
              isHidden: !this.$store.state.routes.systemSetting.MultiWarehouse,
            },
            {
              label: '档口',
              prop: 'StoreName',
              props: ['StoreName'],
              type: 'text',
              isHidden: !this.$store.state.routes.systemSetting.MultiStore,
            },
            {
              label: '数量',
              prop: 'NumInfo',
              props: ['Num', 'OutStockNum'],
              type: 'custom-operation',
              width: '70px',
            },
            {
              label: '金额',
              prop: 'Payment',
              type: 'text-decimal',
            },
            {
              label: '报单时间',
              prop: 'ReportTime',
              type: 'text-datetime',
              dateFormat: '<strong>MM-dd</strong> HH:mm',
              width: '96px',
            },
          ],
        },
        waitCheckStock: {
          url: '/api/stall/getList',
          rowKey: 'RowKey',
          columns: [
            {
              label: '打印时间',
              prop: 'PrintTime',
              type: 'text-datetime',
              dateFormat: '<strong>yy-MM-dd</strong> HH:mm',
              width: '120px',
            },
            {
              label: '客户',
              prop: 'PurchaserName',
              type: 'text',
            },
            {
              label: '仓库',
              prop: 'WName',
              props: ['WName'],
              type: 'text',
              isHidden: !this.$store.state.routes.systemSetting.MultiWarehouse,
            },
            {
              label: '数量',
              prop: 'NumInfo',
              props: ['Num', 'OutStockNum'],
              type: 'custom-operation',
              width: '80px',
            },
            {
              label: '金额',
              prop: 'Payment',
              type: 'text-decimal',
              width: '100px',
            },
          ],
        },
        done: {
          url: '/api/stall/getList',
          rowKey: 'RowKey',
          columns: [
            {
              label: '接单时间',
              prop: 'CreateTime',
              type: 'text-datetime',
              dateFormat: '<strong>yy-MM-dd</strong> HH:mm',
              width: '120px',
            },
            {
              label: '客户',
              prop: 'PurchaserName',
              props: ['PurchaserName', 'ProxyStatus'],
              type: 'custom-operation',
            },
            {
              label: '仓库',
              prop: 'WName',
              type: 'text',
              isHidden: !this.$store.state.routes.systemSetting.MultiWarehouse,
            },
            {
              label: '档口',
              prop: 'StoreName',
              props: ['StoreName'],
              type: 'text',
              isHidden: !this.$store.state.routes.systemSetting.MultiStore,
            },
            {
              label: '数量',
              prop: 'NumInfo',
              props: ['Num', 'OutStockNum'],
              type: 'custom-operation',
              width: '80px',
            },
            {
              label: '金额',
              prop: 'Payment',
              type: 'text-decimal',
              width: '100px',
            },
          ],
        },
      },
      waitCheckStockTableConfig: {
        url: '/api/stall/getList',
        rowKey: 'TradeID',
        columns: [
          {
            label: '接单时间',
            prop: 'CreateTime',
            type: 'text-datetime',
            dateFormat: '<strong>yyyy-MM-dd</strong> HH:mm',
            width: '150px',
          },
          {
            label: '客户',
            prop: 'PurchaserName',
            type: 'text',
          },
          {
            label: '仓库',
            prop: 'WName',
            props: ['WName'],
            type: 'custom-operation',
            isHidden: !this.$store.state.routes.systemSetting.MultiWarehouse,
          },
          {
            label: '数量',
            prop: 'Num',
            type: 'text',
            width: '225px',
          },
          {
            label: '金额',
            prop: 'Payment',
            type: 'text-decimal',
            width: '230px',
          },
        ],
      },
      statistics: {
        purchaserCount: 0,
        totalCount: 0,
        totoalAmount: 0.0,
      },
      selectRow: null,
      selectRows: [],
      configField: {},
      printConfig: {},
      jumpPage: null,
      a4TotalPage: 0,
    };
  },
  methods: {
    async init() {
      await this.$refs.printConfig.getConfig();
      await this.query();
      this.pollingAuto();
    },
    async query() {
      this.$refs.waitPrint.tableData = [];
      this.$refs.waitCheckStock.tableData = [];
      this.$refs.done.tableData = [];
      if (!this.queryParam.date) {
        this.queryParam.date = getDate();
      }
      this.queryParam.startTime = this.queryData.dateRange[0] + ' 00:00:00';
      this.queryParam.endTime = this.queryData.dateRange[1] + ' 23:59:59';
      this.queryParam.withSupplierGroup = this.printConfig.withSupplierGroup;
      this.$refs[this.statusTab].searchParam = {
        ...this.$refs[this.statusTab].searchParam,
        ...this.queryParam,
      };
      if (this.queryParam.status == 5 && this.queryData.supplierQuery) {
        this.$refs[this.statusTab].searchParam.supplierIDs = this.queryData.supplierIDs;
      }
      this.$refs[this.statusTab].fetchData(false, this.getStatistics);
      this.newTradeCount = 0;
    },
    async queryWaitCheckStock() {
      this.$refs.waitCheckStockTable.tableData = [];
      let tradeIDs = getColumnValue('TradeID', this.selectRows);
      if (!tradeIDs) {
        return;
      }
      const { data } = await submit(this.waitCheckStockTableConfig.url, { currentPage: 1, pageSize: 9999999, tradeIDs: tradeIDs });
      this.$refs.waitCheckStockTable.tableData = data.datas;
    },
    getStatistics(datas) {
      this.selectRows = [];
      this.statistics.purchaserCount = 0;
      this.statistics.totalCount = 0;
      this.statistics.totoalAmount = 0;
      let tradeIds = [];
      let purchasers = [];
      let _this = this;
      datas.forEach(function (data) {
        tradeIds.push(data.TradeID);
        let purchaserNameArr = data.PurchaserName.split(',');
        for (let purchaserName of purchaserNameArr) {
          if (!purchasers.includes(purchaserName)) {
            _this.statistics.purchaserCount += 1;
            purchasers.push(purchaserName);
          }
        }
        _this.statistics.totalCount += data.Num - data.OutStockNum;
        _this.statistics.totoalAmount += data.Payment;
      });
      this.$('#print-view .paper-bill,#print-view .paper-a4,#print-view .paper-tag').empty();
      this.jumpPage = null;
      this.$refs.tradePrint.printData = [];
      this.getSupplier(tradeIds);
    },
    async getSupplier(tradeIds) {
      if (this.queryParam.status != 5 && (!this.queryData.supplierQuery || this.queryParam.supplierIDs.length > 0)) {
        return;
      }
      if (this.queryParam.status != 5) {
        this.queryData.suppliers = [];
      }
      if ((this.queryParam.status != 5 && tradeIds.length > 0) || (this.queryParam.status == 5 && this.queryData.allSuppliers.length == 0)) {
        const { data } = await submit('/api/stall/getSupplierList', { tradeIDs: tradeIds.join(','), status: this.queryParam.status });
        if (this.queryParam.status != 5) {
          this.queryData.suppliers = data;
        } else {
          this.queryData.allSuppliers = data;
        }
      }
    },
    pollingAuto() {
      let _this = this;
      setTimeout(() => {
        if (_this && !_this._isDestroyed && _this.autoPolling) {
          _this.getPolling();
          _this.pollingAuto();
        }
      }, 60000);
    },
    async getPolling() {
      const { data } = await submit(this.tableConfig.waitPrint.url, { ...this.queryParam, ...{ currentPage: 1, pageSize: 9999999 } });
      this.newTradeCount = (data.datas ? data.datas : []).length - this.$refs.waitPrint.tableData.length;
    },
    changeStatusTab(tab) {
      this.autoPolling = false;
      switch (tab.name) {
        case 'waitPrint':
          this.queryParam.status = 1;
          if (this.queryData.supplierQuery) {
            this.queryParam.supplierIDs = [];
          }
          this.autoPolling = true;
          this.pollingAuto();
          if (this.printConfig.withSupplierGroup && this.tableConfig.waitPrint.columns[2].isHidden) {
            this.tableConfig.waitPrint.columns[2].isHidden = false;
          } else if (!this.printConfig.withSupplierGroup && !this.tableConfig.waitPrint.columns[2].isHidden) {
            this.tableConfig.waitPrint.columns[2].isHidden = true;
          }
          break;
        case 'waitCheckStock':
          {
            this.queryParam.status = 2;
            if (this.queryData.supplierQuery) {
              this.queryParam.supplierIDs = [];
            }
          }
          break;
        case 'done':
          this.queryParam.status = 5;
          break;
      }
      this.printViewTab = this.printConfig.printView;
      this.query();
    },
    changeSelection(selection) {
      this.selectRows = selection;
      if (this.selectRow) {
        this.dealSelectRow(this.selectRow);
        this.selectRow = null;
      }
    },
    selectSingle(selection, row) {
      this.selectRow = null;
      if (this.statusTab != 'waitCheckStock' && selection.some(data => data.RowKey == row.RowKey)) {
        this.selectRow = row;
      }
      setTimeout(() => {
        this.dealSelected();
      }, 100);
    },
    selectAll() {
      setTimeout(() => {
        this.dealSelected();
      }, 100);
    },
    clickRow(row) {
      this.$refs[this.statusTab].$refs.gridTable.toggleRowSelection(row);
      if (this.statusTab != 'waitCheckStock' && this.selectRows.some(data => data.RowKey == row.RowKey)) {
        this.dealSelectRow(row);
      }
      this.dealSelected();
    },
    dealSelectRow(row) {
      const _this = this;
      this.$refs[this.statusTab].tableData.forEach(function (data) {
        if (row.RowKey != data.RowKey && row.PurchaserName == data.PurchaserName) {
          _this.$refs[_this.statusTab].$refs.gridTable.toggleRowSelection(data, true);
        }
      });
    },
    dealSelected() {
      this.jumpPage = null;
      let tradeIDs = getColumnValue('TradeID', this.selectRows);
      let orderIDs = null;
      if (this.queryParam.status == 1 && this.printConfig.withSupplierGroup) {
        orderIDs = getColumnValue('OrderIDs', this.selectRows);
      }
      let batchNos = null;
      if (this.queryParam.status == 2) {
        batchNos = getColumnValue('PrintBatchNo', this.selectRows);
      }
      this.$refs.tradePrint.getPrintInfo(tradeIDs, '', this.queryParam.status, orderIDs, null, batchNos);
      if (this.statusTab == 'waitCheckStock') {
        this.queryWaitCheckStock();
      }
    },
    async savePrintConfig(key) {
      this.$refs.tradePrint.init(false);
      if (key == 'withSupplierGroup' && this.statusTab == 'waitPrint') {
        await this.changeStatusTab({ name: this.statusTab });
      }
      this.dealSelected();
    },
    changePrintViewTab() {
      this.$nextTick(() => {
        this.$refs.tradePrint.changePrintView();
      });
    },
    jumpToPage() {
      if (this.jumpPage > this.$refs.tradePrint.pageSize.a4.totalPage) {
        return;
      }
      let $wrapper = this.$(`#print-view .paper-a4 .print-wrapper-${this.jumpPage}`)[0];
      this.$('.print-view-main').animate(
        {
          scrollTop: $wrapper.offsetTop + 'px',
        },
        {
          duratioin: 500,
          easing: 'swing',
        }
      );
    },
    async del() {
      var that = this;
      let tradeIDs = getColumnValue('TradeID', that.selectRows);
      if (!tradeIDs) {
        that.$baseMessage('请选择报单', 'error');
        return;
      }
      that.$confirm('确定删除选中的报单？', "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        await submit('/api/stall/delete', { tradeIDs: tradeIDs });
        that.$baseMessage('删除成功', 'success');
        that.query();
      }).catch(() => {
        //取消          
      });
    },
    async recall() {
      let tradeIDs = getColumnValue('TradeID', this.selectRows);
      if (!tradeIDs) {
        this.$baseMessage('请选择报单', 'error');
        return;
      }
      let batchNos = null;
      if (this.queryParam.status == 2) {
        batchNos = getColumnValue('PrintBatchNo', this.selectRows);
      }
      await submit('/api/stall/recall', { tradeIDs: tradeIDs, batchNos: batchNos, status: this.queryParam.status, skipCheckStock: this.printConfig.skipCheckStock });
      this.$baseMessage('撤回成功', 'success');
      this.query();
    },
    async print() {
      let tradeIDs = getColumnValue('TradeID', this.selectRows);
      await this.$refs.tradePrint.print(tradeIDs, this.queryParam.status, '', this.getPrintTradeInfo());
    },
    async savePrint() {
      let tradeIDs = getColumnValue('TradeID', this.selectRows);
      await this.$refs.tradePrint.savePrint(tradeIDs, this.queryParam.status, null, false, this.getPrintTradeInfo());
    },
    getPrintTradeInfo() {
      let tradeInfo = null;
      if (this.queryParam.status == 1 && this.printConfig.withSupplierGroup) {
        tradeInfo = {};
        for (let row of this.selectRows) {
          if (row.TradeID in tradeInfo) {
            tradeInfo[row.TradeID] += ',' + row.OrderIDs;
          }
          else {
            tradeInfo[row.TradeID] = row.OrderIDs;
          }
        }
      }
      return tradeInfo;
    },
    async saveCheckStock() {
      if (this.selectRows.length == 0) {
        this.$baseMessage('请选择要登记的报单', 'error');
        return;
      }
      let tradeIDs = [];
      let batchNos = [];
      for (let row of this.selectRows) {
        tradeIDs.push(row.TradeID);
        batchNos.push(row.PrintBatchNo);
      }
      let _this = this;
      let outStockList = [];
      this.$(`#print-view .paper-${this.printViewTab} .out-stock`).each(function (i, item) {
        let $item = _this.$(item);
        outStockList.push({
          purchaserName: $item.attr('data-purchaser_name'),
          itemNo: $item.attr('data-item_no'),
          specName: $item.attr('data-spec_name'),
          num: $item.val(),
        });
      });
      await submit('/api/stall/checkStock', { tradeIDs: tradeIDs.join(','), BatchNos: batchNos.join(','), checkStockBySpec: this.printConfig.checkStockBySpec, outStockList: outStockList });
      this.$baseMessage('登记成功', 'success');
      this.query();
    },
    async sendBill() {
      let tradeIDs = getColumnValue('TradeID', this.selectRows);
      if (!tradeIDs) {
        this.$message({ type: 'error', message: '请选择订单' });
      }
      await this.$refs.tradePrint.sendBill();
    },
  },
};
</script>

<style lang="scss" scoped>
#allocation-content {
  min-width: 1760px;

  #trade {

    .search {
      display: inline-block;
      margin-right: 5px;
      position: relative;

      .el-range-editor {
        width: 200px;
        padding: 3px 5px;

        ::v-deep {
          .el-range__close-icon {
            width: 5px;
          }
        }
      }

      .el-select:nth-child(1) {
        width: 90px;
        margin-right: 5px;
      }

      .el-select:nth-child(2),
      .el-select:nth-child(3) {
        width: 170px;

        ::v-deep {
          .el-tag:first-child {
            display: inline-block;
            max-width: 59px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .el-tab:last-child {
            margin-left: 3px;
          }
        }
      }

      .el-input {
        width: 170px;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    ::v-deep {
      .el-footer {
        font-size: 15px;
        font-weight: bold;

        span {
          display: inline-block;
          width: 33%;
          line-height: 40px;
          vertical-align: middle;

          &:last-child {
            text-align: right;
          }
        }
      }
    }

    .proxy-status {
      &::before {
        content: "代";
        height: 20px;
        width: 20px;
        position: absolute;
        box-sizing: border-box;
        top: 0px;
        left: 0px;
        color: #ffffff;
        background: #0075E3;
        border-bottom-right-radius: 15px;
        padding: 0;
        font-size: 10px;
        z-index: 50;
      }
    }
  }

  #print-view {
    ::v-deep {
      .el-main {
        max-width: 260mm;
        height: 100%;
        background: #a3a3a3;
        padding: 0;
        position: relative;
        box-sizing: border-box;
        overflow: auto;

        .paper-wrapper {
          min-height: 100%;
          display: flex;
          align-content: center;
          justify-content: center;
          flex-wrap: wrap;
        }
      }

      .el-footer {
        &>span {
          line-height: 40px;
          vertical-align: middle;
        }

        >span:first-child {
          .el-input {
            width: 50px;
            margin: 0 5px;

            .el-input__inner {
              text-align: center;
            }
          }
        }

        >span:last-child {
          float: right;

          .el-button {
            width: 100px;
          }
        }
      }
    }

    .print-config {
      position: absolute;
      top: 3px;
      right: 0;
    }

    .print-config-eidt {
      .el-row {
        margin-bottom: 8px;
      }

      .edit-title {
        text-align: right;
        line-height: 18px;

        &.edit-title-big {
          line-height: 32px;
        }
      }

      ::v-deep {
        .el-tabs__item {
          color: #606266 !important;

          &:first-child {
            padding-left: 32px;
          }

          &.is-active {
            color: #409eff !important;
          }
        }
      }

      .el-input {
        width: 80px;
        margin: 0 5px;
      }
    }
  }

  main {
    position: relative;

    .print-view-left {
      position: absolute;
      top: 80px;
      right: 0;
      z-index: 99;

      >div {
        background: white;
        margin-bottom: 5px;
        height: 32px;
        width: 65px;
        line-height: 32px;
        text-align: center;
        border: 1px solid #a3a3a3;
        border-right: none;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        cursor: pointer;

        &.is-active {
          color: #409eff;
          border-color: #409eff;
        }
      }
    }
  }
}</style>